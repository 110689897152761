jQuery(document).ready(function(jQuery) {

	//jQuery('.contatos').clone().insertAfter('.shiftnav .shiftnav-inner .shiftnav-nav');

	jQuery('nav a').smoothScroll();

});

jQuery(window).load(function() {
    // will first fade out the loading animation
	jQuery("#loader").delay(900).fadeOut("slow");
    // will fade out the whole DIV that covers the website.
	jQuery("#fulloverlay").delay(500).fadeOut("slow");

	jQuery('body').css('overflow', 'auto');
});

// REDUZ TAMANHO DO MENU NO SCROLL 
/*jQuery(document).on( 'scroll', function(){
    var y = jQuery(window).scrollTop();
    if(y>94){
    	jQuery('.site-header').addClass('smaller');
    }else{
    	jQuery('.site-header').removeClass('smaller');
    }
});*/

// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = jQuery('header').outerHeight();

jQuery(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = jQuery(this).scrollTop(); 
    
    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;
    
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        jQuery('header').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if(st + jQuery(window).height() < jQuery(document).height()) {
            jQuery('header').removeClass('nav-up').addClass('nav-down');
        }
    }
    
    lastScrollTop = st;
}
